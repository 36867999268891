import LayoutAnimation, { LayoutAnimationStaggerParent } from '@/components/framerAnimations/layoutAnimation';
import StyledImage from '@/components/styledImage';
import { Box, Container, Grid, Typography } from '@mui/material';
import { Variants } from 'framer-motion';

export default function BusinessBenefitsSection() {
	
	const animation: Variants = {
		offscreen: { y: 10, opacity: 0 },
		onscreen : {
			y         : 0,
			opacity   : 1,
			transition: {
				type    : 'spring',
				duration: .5,
			},
		},
	};
	
	return (
		<Container
			disableGutters
			maxWidth='xl'
			className='borderedContainer'
			sx={{ p: { xs: 5, md: 10, lg: 15, xl: 25 } }}>
			<LayoutAnimationStaggerParent transition={{ staggerChildren: 0.2 }}>
				<Grid container spacing={5}>
					<Grid item xs={12} mt={2} mb={5}>
						<LayoutAnimation hasStagger animationConfig={animation}>
							<Typography className='sectionTitle' width={{ xs: '100%', sm: '60%' }}>
								All kinds of Clover businesses can take advantage of Invoiss to enhance their sales and
								growth!
							</Typography>
						</LayoutAnimation>
					</Grid>
					<Grid item xs={12} sm={6} lg={4}>
						<LayoutAnimation hasStagger animationConfig={animation}>
							<Box>
								<StyledImage
									alt='invoiss icon'
									src='/images/static-images/restaurant-industry.jpg'
									sx={{ width: '100%', height: { xs: 'auto', sm: 500 }, borderRadius: 3, mb: 2 }}
								/>
								<Typography gutterBottom className='sectionSubTitle' fontWeight='500'>
									Restaurants
								</Typography>
								<Typography className='paragraph'>
									Increase your sales by offering your customers the ability to order online and pay
									with their credit card.
								</Typography>
							</Box>
						</LayoutAnimation>
					</Grid>
					<Grid item xs={12} sm={6} lg={4}>
						<LayoutAnimation hasStagger animationConfig={animation}>
							<Box>
								<StyledImage
									alt='invoiss icon'
									src='/images/static-images/retail-industry.jpg'
									sx={{ width: '100%', height: { xs: 'auto', sm: 500 }, borderRadius: 3, mb: 2 }}
								/>
								<Typography gutterBottom className='sectionSubTitle' fontWeight='500'>
									Retail
								</Typography>
								<Typography className='paragraph'>
									Empower your business with a powerful invoicing solution that syncs simultaneously
									with your clover account.
								</Typography>
							</Box>
						</LayoutAnimation>
					</Grid>
					<Grid item xs={12} sm={6} lg={4}>
						<LayoutAnimation hasStagger animationConfig={animation}>
							<Box>
								<StyledImage
									alt='invoiss icon'
									src='/images/static-images/wholesale-industry-3.jpg'
									sx={{ width: '100%', height: { xs: 'auto', sm: 500 }, borderRadius: 3, mb: 2 }}
								/>
								<Typography gutterBottom className='sectionSubTitle' fontWeight='500'>
									Wholesale
								</Typography>
								<Typography className='paragraph'>
									Control your inventory with an automated algorithm that counts every item sold in
									your invoices and orders.
								</Typography>
							</Box>
						</LayoutAnimation>
					</Grid>
				</Grid>
			</LayoutAnimationStaggerParent>
		</Container>
	);
}
