import LayoutAnimation, { LayoutAnimationStaggerParent } from '@/components/framerAnimations/layoutAnimation';
import { Box, Container, Divider, Stack, Theme, Typography, useMediaQuery } from '@mui/material';

export default function SummarizedPricingSection() {
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	return (
		<Container
			disableGutters
			maxWidth='xl'
			className='borderedContainer'
			sx={{ p: { xs: 5, md: 10, lg: 15, xl: 25 }, bgcolor: '#F2F2F2' }}>
			<LayoutAnimationStaggerParent>
				<Stack
					alignItems='center'
					textAlign='center'
					spacing={4}
					sx={{ px: { xs: 1, md: 10, lg: 25 } }}>
					<LayoutAnimation hasStagger duration={1} slideUpDistance={90} variant='slideUp'>
						<Typography className='sectionTitle'>
							One simple pricing plan for all
						</Typography>
					</LayoutAnimation>
					<LayoutAnimation hasStagger duration={1} slideUpDistance={90} variant='slideUp'>
						<Typography className='sectionSubTitle'>
							Start with a free 14 day trial and premium access.
						</Typography>
					</LayoutAnimation>
					<LayoutAnimation hasStagger duration={1} slideUpDistance={90} variant='slideUp'>
						<Box
							sx={{
								'borderRadius'            : 5,
								'pb'                      : { xl: 0 },
								'bgcolor'                 : '#ffffff',
								'width'                   : { xs: 400, md: 700, xl: 1000 },
								'backdropFilter'          : 'blur( 10px )',
								'textAlign'               : 'center',
								'mt'                      : 5,
								'.pricingBoxContent'      : {
									position  : 'relative',
									transition: '0.5s',
								},
								':hover'                  : {
									'filter'            : 'brightness(.99)',
									'.pricingBoxContent': {
										transform: 'translateY(-20px)',
									},
									'.pricingLearnMore' : {
										transform: 'translateY(0px)',
										opacity  : 1,
									},
								},
								'.pricingLearnMore'       : {
									position  : 'absolute',
									bottom    : 10,
									right     : 0,
									left      : 0,
									transform : 'translateY(40px)',
									transition: '0.5s',
									opacity   : 0,
								},
								'.pricingLearnMoreContent': {
									'fontWeight': 'bold',
									':hover'    : {
										textDecoration: 'underline',
										cursor        : 'pointer',
									},
								},
							}}>
							<Box
								className='pricingBoxContent'
								sx={{
									width        : '100%',
									display      : 'flex',
									flexDirection: { xs: 'column', md: 'row' },
									p            : { xs: 1, xl: 5 },
								}}>
								<Box
									sx={{
										width         : { xs: '100%', md: '25%' },
										p             : 4,
										display       : 'flex',
										alignItems    : 'center',
										justifyContent: 'center',
										borderBottom  : isMobile && 1,
										borderColor   : 'divider',
									}}>
									<Typography className='sectionSubTitle'>
										Sign up FREE
									</Typography>
								</Box>
								<Divider flexItem orientation='vertical' variant='middle'/>
								<Box
									sx={{
										width         : { xs: '100%', md: '50%' },
										p             : 4,
										display       : 'flex',
										alignItems    : 'center',
										justifyContent: 'center',
										borderBottom  : isMobile && 1,
										borderColor   : 'divider',
									}}>
									<Box>
										<Typography className='sectionSubTitle'>
											Try 14 days
										</Typography>
									</Box>
								</Box>
								<Divider flexItem orientation='vertical' variant='middle'/>
								<Box
									sx={{
										width         : { xs: '100%', md: '25%' },
										p             : 4,
										display       : 'flex',
										alignItems    : 'center',
										justifyContent: 'center',
									}}>
									<Typography className='sectionSubTitle'>
										Cancel any time
									</Typography>
								</Box>
							</Box>
							<Box className='pricingLearnMore' sx={{ py: 2 }}>
								<Typography
									component='a'
									target='_blank'
									href='https://www.clover.com/appmarket/apps/9TDMF3T5AN9T2'
									className='paragraph pricingLearnMoreContent'>
									Learn More
								</Typography>
							</Box>
						</Box>
					</LayoutAnimation>
				</Stack>
			</LayoutAnimationStaggerParent>
		</Container>
	);
}
