import LayoutAnimation, { LayoutAnimationStaggerParent } from '@/components/framerAnimations/layoutAnimation';
import StyledImage from '@/components/styledImage';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Container, Stack, Typography } from '@mui/material';
import StyledCustomButton from '../buttons/staticPageButton';
import { slideLeft } from './accordionSection';

export default function AccordionMultipleLocationFeature() {
	return (
		<Accordion>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon className='accordionIcon'/>}
				sx={{ p: 2, px: { md: 5, lg: 25 } }}>
				<LayoutAnimation hasStagger animationConfig={slideLeft}>
					<Stack direction='row' spacing={3} alignItems='center'>
						<StyledImage
							alt='accordion-logo'
							src='images/static-images/multilocation-accordion.png'
							sx={{ width: { xs: 70, sm: 150 }, height: 'auto' }}
						/>
						<Typography className='accordionTitle'>Multiple Location Support</Typography>
					</Stack>
				</LayoutAnimation>
			</AccordionSummary>
			<AccordionDetails sx={{ py: { xs: 5, sm: 20 } }}>
				<LayoutAnimationStaggerParent>
					<Container maxWidth='md'>
						<LayoutAnimation hasStagger variant='slideUp'>
							<Typography gutterBottom className='sectionTitle' textAlign='center'>
								Multiple locations from one account
							</Typography>
						</LayoutAnimation>
						<LayoutAnimation hasStagger variant='slideUp'>
							<Typography className='sectionSubTitle' textAlign='center'>
								Set permissions for your employees to manage each location separately
							</Typography>
						</LayoutAnimation>
						<LayoutAnimation hasStagger variant='slideUp' style={{ marginTop: '25px' }}>
							<StyledImage
								alt='multi-location'
								src='/images/static-images/multi-location-map.png'
								sx={{
									height        : { xs: 'auto', sm: 500 },
									width         : '100%',
									imageRendering: '-webkit-optimize-contrast !important',
								}}
							/>
						</LayoutAnimation>
						<LayoutAnimation hasStagger variant='slideUp' style={{ marginTop: '25px' }}>
							<Stack spacing={1} pt={5} alignItems='center' justifyContent='center'>
								<StyledCustomButton
									href='https://www.clover.com/appmarket/apps/9TDMF3T5AN9T2'
									color='#280'
									target='_blank'>
									Connect Now
								</StyledCustomButton>
								<Typography className='connectTrial' textAlign='center'>
									FREE 14-days
								</Typography>
							</Stack>
						</LayoutAnimation>
					</Container>
				</LayoutAnimationStaggerParent>
			</AccordionDetails>
		</Accordion>
	);
}
