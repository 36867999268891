import EmailSignupForm from '@/components/emailSignUpform';
import LayoutAnimation, { LayoutAnimationStaggerParent } from '@/components/framerAnimations/layoutAnimation';
import StyledImage from '@/components/styledImage';
import animatedLinearGradient from '@/helpers/animtedLinearGradient';
import StyledCustomButton from '@/pages/static/common/buttons/staticPageButton';
import { useModal } from '@/providers/modal';
import { CalendarMonth as CalendarMonthIcon, PlayCircleRounded as PlayCircleRoundedIcon } from '@mui/icons-material';
import { Box, Container, Grid, keyframes, Stack, Typography } from '@mui/material';
import { Variants } from 'framer-motion';

function VideoModal() {
	return (
		<iframe
			allowFullScreen
			width='100%'
			height='500'
			src='https://www.youtube.com/embed/IiXmuEOXY48?si=LLLcLY-9v-wTxQOl&autoplay=1&loop=1&controls=1&showinfo=1&hd=1&rel=0'
			title='Invoiss Introduction (BEST CLOVER INVOICING)'
			allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
			frameBorder='0'
		/>
	);
}

function VideoInvoiss() {
	return (
		<iframe
			allowFullScreen
			width='100%'
			height='100%'
			src='https://www.youtube.com/embed/24nR9O_-278?si=sCHJ__t-al7Vs5Ow&autoplay=1&loop=1&controls=1&showinfo=1&hd=1&rel=0&vq=hd1080'
			title='Invoiss Introduction (BEST CLOVER INVOICING)'
			allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
			frameBorder='0'
		/>
	);
}

const shimmer = keyframes`
	0% {
		background-position : -1000px 0;
	}
	100% {
		background-position : 1000px 0;
	}
`;

export default function HomeHeroSection() {
	const { showModal } = useModal();
	
	const animationVariantOne: Variants = {
		offscreen: { y: 10, opacity: 0 },
		onscreen : {
			y         : 0,
			opacity   : 1,
			transition: {
				type    : 'spring',
				duration: .5,
			},
		},
	};
	
	return (
		<Container
			disableGutters
			maxWidth='xl'
			className='borderedContainer'
			sx={{
				p       : { xs: 5, md: 10, lg: 15, xl: 25 },
				pt      : { xs: 80, sm: 60, md: 60, lg: 50, xl: 45 }, // temporary
				position: 'relative', // temporary
				// background: ( { palette } ) => `conic-gradient(from 90deg at .5px .5px, transparent 90deg, ${palette.divider} 0) 0 0/180px 180px`,
			}}>
			<Box
				sx={{
					// bgcolor: '#000000',
					...animatedLinearGradient( [
						'#7ec785',
						'#4d7a4f',
						'#2c2c2c',
						'#151515',
						'#000000',
					], 'to top left' ),
					position    : 'absolute',
					top         : 0,
					m           : 4,
					left        : 0,
					right       : 0,
					py          : 6,
					px          : { xs: 1, lg: 10 },
					borderRadius: 3,
					
					display      : 'flex',
					flexDirection: 'column',
				}}>
				<Typography
					sx={{
						textAlign: 'center',
						fontSize : '18px !important',
						color    : '#b4b4b4',
					}}>
					For agents, ISOs and banks
				</Typography>
				<Typography
					sx={{
						textAlign : 'center',
						fontSize  : '40px !important',
						fontWeight: 'bold',
						color     : '#ffffff',
					}}>
					Join our Webinar on February 21 2024 8am (PST)
				</Typography>
				<Typography
					sx={{
						textAlign: 'center',
						fontSize : '23px !important',
						color    : '#b4b4b4',
					}}>
					Tune in to learn about how Invoiss is helping thousands of businesses in sales and purchasing.
				</Typography>
				<Box sx={{ alignSelf: 'center', mt: 4 }}>
					<EmailSignupForm
						tag='webinar'
						buttonText='Register'
						paperProps={{
							sx: {
								width: '100%',
							},
						}}
					/>
				</Box>
			</Box>
			
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<LayoutAnimationStaggerParent transition={{ staggerChildren: 0.2 }}>
						<Stack spacing={2}>
							<LayoutAnimation hasStagger animationConfig={animationVariantOne}>
								<Stack direction='row' alignItems='center' spacing={1} mb={2}>
									<StyledImage
										alt='invoiss-logo'
										src='/images/invoiss-logo-dark.png'
										sx={{ width: 80, height: 80 }}
									/>
									{/*<AddRoundedIcon sx={{ fontSize: 40 }}/>*/}
									{/*<StyledImage*/}
									{/*	alt='clover-logo'*/}
									{/*	src='/images/clover-icon.png'*/}
									{/*	sx={{ width: 80, height: 80 }}*/}
									{/*/>*/}
								</Stack>
							</LayoutAnimation>
							<LayoutAnimation hasStagger animationConfig={animationVariantOne}>
								<Typography className='mainSectionTitle'>
									Advanced Invoicing & Purchasing
								</Typography>
							</LayoutAnimation>
							<LayoutAnimation hasStagger animationConfig={animationVariantOne}>
								<Typography className='mainSectionSubtitle'>
									Elevate your business with a top-tier suite of professional tools.
								</Typography>
							</LayoutAnimation>
							<LayoutAnimation hasStagger animationConfig={animationVariantOne}>
								<Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} mt={3}>
									<StyledCustomButton
										color='#276a16'
										href='https://www.clover.com/appmarket/apps/9TDMF3T5AN9T2'
										target='_blank'>
										Connect
									</StyledCustomButton>
									<StyledCustomButton
										endIconColor='#276a16'
										endIcon={<PlayCircleRoundedIcon className='arrowRight'/>}
										onClick={() => showModal( VideoModal, {} )}>
										Watch Demo
									</StyledCustomButton>
								</Stack>
								<StyledCustomButton
									target='_blank'
									variant='text'
									color='primary'
									sx={{ mt: 2, px: 2 }}
									endIcon={<CalendarMonthIcon sx={{ color: 'primary.main', ml: 1 }}/>}
									href='https://calendly.com/invoiss/invoiss-introduction'>
									Schedule a Demo
								</StyledCustomButton>
							</LayoutAnimation>
						</Stack>
					</LayoutAnimationStaggerParent>
				</Grid>
				<Grid item xs={12} lg={6} sx={{ position: 'relative' }}>
					<Box
						className='center'
						sx={{
							'height'            : 480,
							'width'             : '100%',
							'backgroundImage'   : 'url(/images/static-images/demo-bg.png)',
							'backgroundSize'    : 'cover',
							'backgroundRepeat'  : 'no-repeat',
							'backgroundPosition': 'center',
							'borderRadius'      : 5,
							'transition'        : 'all 0.2s',
							'boxShadow'         : 25,
							'position'          : { lg: 'absolute' },
							'mt'                : { xs: 5, lg: 'unset' },
							'top'               : 50,
							'left'              : 100,
							'&:hover'           : {
								cursor   : 'pointer',
								transform: 'scale(1.05)',
							},
						}}
						onClick={() => showModal( VideoInvoiss, { fullPageModal: true, maxWidth: 'lg' } )}>
						<PlayCircleRoundedIcon
							sx={{
								'fontSize'  : 100,
								'color'     : 'white',
								'boxShadow' : 25,
								'transition': 'all 0.2s',
								'&:hover'   : {
									boxShadow: 0,
									color    : '#000000',
									transform: 'scale(1.2)',
								},
							}}
						/>
					</Box>
				</Grid>
			</Grid>
		</Container>
	);
}
