import { LayoutAnimationStaggerParent } from '@/components/framerAnimations/layoutAnimation';
import { Box } from '@mui/material';
import { Variants } from 'framer-motion';

export default function StaticPageBoxWithStylesWrapper( { children } ) {
	const animationFadeInBackground2: Variants = {
		offscreen: {
			opacity        : 0,
			color          : '#ffffff',
			backgroundColor: '#000000',
		},
		onscreen : {
			opacity        : 1,
			transition     : {
				duration: 1.5,
				ease    : 'easeInOut',
			},
			color          : '#000000',
			backgroundColor: '#ffffff',
		},
	};
	
	return (
		<LayoutAnimationStaggerParent>
			{/*<LayoutAnimation hasStagger animationConfig={animationFadeInBackground2}>*/}
			<Box
				sx={{
					'.mainSectionTitle'      : {
						fontWeight: { xs: '500', sm: 'bold' },
						fontSize  : { xs: '4rem !important', sm: '5.5rem !important' },
						lineHeight: 1.2,
					},
					'.mainSectionSubtitle'   : {
						color     : 'text.secondary',
						fontSize  : { xs: '2rem !important', sm: '3rem !important' },
						lineHeight: 1.2,
					},
					'.mainSectionSubtitle2'  : {
						color     : 'text.secondary',
						fontSize  : { xs: '1.6rem !important', sm: '2rem !important' },
						lineHeight: 1.2,
					},
					'.connectTrial'          : {
						color     : 'text.secondary',
						fontSize  : { xs: '1.2rem !important', sm: '1.8rem !important' },
						lineHeight: .9,
					},
					'.accordionTitle'        : {
						fontWeight: 500,
						fontSize  : { xs: '2.2rem !important', sm: '3.5rem !important' },
					},
					'.sectionTitle'          : {
						fontWeight: 500,
						fontSize  : { xs: '3.5rem !important', sm: '4.5rem !important' },
					},
					'.sectionTitle2'         : {
						fontWeight: 500,
						fontSize  : { xs: '2.5rem !important', sm: '3rem !important' },
					},
					'.sectionSubTitle'       : {
						fontSize: { xs: '1.8rem !important', sm: '2.5rem !important' },
					},
					'.faqTitle'              : {
						fontWeight: 500,
						fontSize  : { xs: '1.5rem !important', sm: '2rem !important' },
					},
					'.faqContent'            : {
						fontSize: { xs: '1.5rem !important', sm: '1.5rem !important' },
					},
					'.paragraph'             : {
						fontSize: { xs: '1.5rem !important', sm: '1.8rem !important' },
					},
					'.sectionContentTitle'   : {
						fontWeight: 700,
						fontSize  : { xs: '3.5rem !important', sm: '3.5rem !important' },
					},
					'.sectionContentSubTitle': {
						fontSize: { xs: '1.5rem !important', sm: '2.2rem !important' },
					},
				}}>
				{children}
			</Box>
			{/*</LayoutAnimation>*/}
		</LayoutAnimationStaggerParent>
	);
}
