import LayoutAnimation, { LayoutAnimationStaggerParent } from '@/components/framerAnimations/layoutAnimation';
import StyledImage from '@/components/styledImage';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Container, Grid, Stack, Typography } from '@mui/material';
import StyledCustomButton from '../buttons/staticPageButton';
import { slideLeft } from './accordionSection';

export default function AccordionInvoicesEstimatesFeature() {
	return (
		<Accordion>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon className='accordionIcon'/>}
				sx={{ p: 2, px: { md: 5, lg: 25 } }}>
				<LayoutAnimation hasStagger animationConfig={slideLeft}>
					<Stack direction='row' spacing={3} alignItems='center'>
						<StyledImage
							alt='accordion-logo'
							src='images/static-images/invoice-estimate-accordion.png'
							sx={{ width: { xs: 70, sm: 150 } }}
						/>
						<Typography className='accordionTitle'>Invoicing and Estimating</Typography>
					</Stack>
				</LayoutAnimation>
			</AccordionSummary>
			<AccordionDetails sx={{ py: { xs: 5, sm: 20 } }}>
				<LayoutAnimationStaggerParent>
					<Container maxWidth='lg'>
						<LayoutAnimation hasStagger variant='slideUp'>
							<Typography gutterBottom className='sectionTitle' textAlign='center'>
								Generate estimates and turn them into work orders or invoices.
							</Typography>
						</LayoutAnimation>
						<LayoutAnimation hasStagger variant='slideUp'>
							<Typography className='sectionSubTitle' textAlign='center'>
								An easy-to-use system for quickly creating and sending invoices and estimates to customers.
							</Typography>
							<Typography className='sectionSubTitle' textAlign='center' color='primary'>
								Update stock counts for all your items on every invoice you create.
							</Typography>
						</LayoutAnimation>
						<Grid container spacing={5} my={{ xs: 2, md: 5 }}>
							<Grid item xs={12} md={4}>
								<LayoutAnimation hasStagger variant='slideRight'>
									<Stack>
										<Typography className='sectionContentTitle'>
											Quote to transaction
										</Typography>
										<Typography className='sectionContentSubTitle' color='text.secondary'>
											Submit a proposal, engage with prospect requests, convert to work
											orders and generate invocies.
										</Typography>
									</Stack>
								</LayoutAnimation>
							</Grid>
							<Grid item xs={12} md={4}>
								<LayoutAnimation hasStagger variant='slideLeft'>
									<Stack>
										<Typography className='sectionContentTitle'>
											Advanced Invoicing
										</Typography>
										<Typography className='sectionContentSubTitle' color='text.secondary'>
											Generate invoices, attach contract agreement, schedule payments, accept deposits, send
											reminders, receive payments, and download or send statements.
										</Typography>
									</Stack>
								</LayoutAnimation>
							</Grid>
							<Grid item xs={12} md={4}>
								<LayoutAnimation hasStagger variant='slideRight'>
									<Stack>
										<Typography className='sectionContentTitle'>
											Get paid online
										</Typography>
										<Typography className='sectionContentSubTitle'>
											Enable your clients to settle their invoices online via card or bank transfer, with
											real-time updates to your Clover reports.
										</Typography>
									</Stack>
								</LayoutAnimation>
							</Grid>
							<Grid container spacing={1} sx={{ my: 10 }}>
								<Grid item xs={12} md={4}>
									<LayoutAnimation hasStagger variant='slideLeft'>
										<StyledImage
											alt='invoiss icon'
											src='/images/static-images/approve.png'
											sx={{ width: '100%', borderRadius: 3 }}
										/>
									</LayoutAnimation>
								</Grid>
								<Grid item xs={12} md={4}>
									<LayoutAnimation hasStagger variant='slideRight'>
										<StyledImage
											alt='invoiss icon'
											src='/images/static-images/illustration-invoice.png'
											sx={{ width: '100%', borderRadius: 3 }}
										/>
									</LayoutAnimation>
								</Grid>
								<Grid item xs={12} md={4}>
									<LayoutAnimation hasStagger variant='slideLeft'>
										<StyledImage
											alt='invoiss icon'
											src='/images/static-images/makePayment.png'
											sx={{ width: '100%', borderRadius: 3 }}
										/>
									</LayoutAnimation>
								</Grid>
							</Grid>
						</Grid>
						<LayoutAnimation hasStagger variant='slideUp'>
							<Typography gutterBottom className='sectionTitle' textAlign='center'>
								Client Portal
							</Typography>
						</LayoutAnimation>
						<LayoutAnimation hasStagger variant='slideUp'>
							<Typography className='sectionSubTitle' textAlign='center'>
								Your clients will be thrilled!
								They can view or download their invoices and statements, manage their saved cards, pay their
								invoices, and sync everything with their QuickBooks.
							</Typography>
						</LayoutAnimation>
						<LayoutAnimation hasStagger variant='slideUp'>
							<Stack spacing={1} pt={5} alignItems='center' justifyContent='center'>
								<StyledCustomButton
									href='https://www.clover.com/appmarket/apps/9TDMF3T5AN9T2'
									color='#280'
									target='_blank'>
									Connect Now
								</StyledCustomButton>
								<Typography className='connectTrial' textAlign='center'>
									FREE 14-days
								</Typography>
							</Stack>
						</LayoutAnimation>
					</Container>
				</LayoutAnimationStaggerParent>
			</AccordionDetails>
		</Accordion>
	);
}
