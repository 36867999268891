import LayoutAnimation, { LayoutAnimationStaggerParent } from '@/components/framerAnimations/layoutAnimation';
import StyledImage from '@/components/styledImage';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Box, Container, Grid, Stack, Typography } from '@mui/material';
import StyledCustomButton from '../buttons/staticPageButton';
import VerticalDivider from '../verticalDivider';
import { slideLeft } from './accordionSection';

export default function AccordionInventoryManagementFeature() {
	return (
		<Accordion>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon className='accordionIcon'/>}
				sx={{ p: 2, px: { md: 5, lg: 25 } }}>
				<LayoutAnimation hasStagger animationConfig={slideLeft}>
					<Stack direction='row' spacing={3} alignItems='center'>
						<StyledImage
							alt='accordion-logo'
							src='images/static-images/inventory-accordion.png'
							sx={{ width: { xs: 70, sm: 150 } }}
						/>
						<Typography className='accordionTitle'>Inventory Management</Typography>
					</Stack>
				</LayoutAnimation>
			</AccordionSummary>
			<AccordionDetails sx={{ py: { xs: 5, sm: 20 } }}>
				<LayoutAnimationStaggerParent>
					<Container maxWidth='md'>
						<LayoutAnimation hasStagger variant='slideUp'>
							<Typography gutterBottom className='sectionTitle' textAlign='center'>
								Full inventory control at your finger tip.
							</Typography>
						</LayoutAnimation>
						<LayoutAnimation hasStagger variant='slideUp'>
							<Typography className='sectionSubTitle' textAlign='center'>
								Generate a QR code for all your items, and scan the code using your phone camera
								to create an invoice or take inventory.
							</Typography>
						</LayoutAnimation>
						<Grid container spacing={5} py={{ xs: 5, md: 20 }}>
							<Grid item xs={12}>
								<Grid
									container
									item
									sx={{
										display       : 'flex',
										flexDirection : 'column',
										justifyContent: 'center',
										alignItems    : 'center',
									}}>
									<Grid container item>
										<Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'end' }}>
											<LayoutAnimation hasStagger variant='slideLeft'>
												<Stack>
													<Typography className='sectionContentTitle'>
														Generate QR or use your code
													</Typography>
													<Typography className='sectionContentSubTitle'>
														With Invoiss you can use your product code to scan products
													</Typography>
												</Stack>
											</LayoutAnimation>
										</Grid>
										<Grid item xs={12} sm={8}>
											<LayoutAnimation
												hasStagger
												variant='slideRight'>
												<Box
													sx={{
														width           : 800,
														height          : { xs: 200, sm: 400 },
														backgroundImage : 'url( /images/static-images/inventory-invoiss.png)',
														backgroundSize  : 'contain',
														backgroundRepeat: 'no-repeat',
													}}
												/>
											</LayoutAnimation>
										</Grid>
									</Grid>
									<VerticalDivider/>
									<Grid container item>
										<Grid item xs={12} sm={4}/>
										<Grid item xs={12} sm={4}>
											<LayoutAnimation
												hasStagger
												variant='slideLeft'
												styles={{ width: '100%', height: '100%' }}>
												<Box
													sx={{
														width           : '100%',
														height          : 400,
														backgroundImage : 'url( /images/static-images/scanning.png)',
														backgroundSize  : 'cover',
														backgroundRepeat: 'no-repeat',
														p               : 2,
														textAlign       : 'center',
														m               : 'auto',
													}}
												/>
											</LayoutAnimation>
										</Grid>
										<Grid item xs={12} sm={4}>
											<LayoutAnimation hasStagger variant='slideRight'>
												<Stack sx={{ pl: 3 }}>
													<Typography className='sectionContentTitle'>
														Use your phone camera
													</Typography>
													<Typography className='sectionContentSubTitle'>
														Scan the QR, product code, UPC or any code linked to your items
														using your phone camera.
													</Typography>
												</Stack>
											</LayoutAnimation>
										</Grid>
									</Grid>
									<VerticalDivider/>
									<Grid container item>
										<Grid item xs={12} sm={4} sx={{ display: 'flex', justifyContent: 'end' }}>
											<LayoutAnimation hasStagger variant='slideLeft'>
												<Stack>
													<Typography className='sectionContentTitle'>
														Keep track of it
													</Typography>
													<Typography className='sectionContentSubTitle'>
														Invoiss will deduct your stock on every invoice, order and add to
														your stock for your purchase orders.
													</Typography>
												</Stack>
											</LayoutAnimation>
										</Grid>
										<Grid item xs={12} sm={8}>
											<LayoutAnimation hasStagger variant='slideRight'>
												<Box
													sx={{
														height          : { xs: 200, sm: 450 },
														backgroundImage : 'url( /images/static-images/clover-inventory-counts.png)',
														backgroundSize  : 'contain',
														width           : '100%',
														backgroundRepeat: 'no-repeat',
													}}
												/>
											</LayoutAnimation>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<LayoutAnimation hasStagger variant='slideUp' styles={{ width: '100%', height: '100%' }}>
							<Stack spacing={1} pt={5} alignItems='center' justifyContent='center'>
								<StyledCustomButton
									href='https://www.clover.com/appmarket/apps/9TDMF3T5AN9T2'
									color='#280'
									target='_blank'>
									Connect Now
								</StyledCustomButton>
								<Typography className='connectTrial' textAlign='center'>
									FREE 14-days
								</Typography>
							</Stack>
						</LayoutAnimation>
					</Container>
				</LayoutAnimationStaggerParent>
			</AccordionDetails>
		</Accordion>
	);
}
