import LayoutAnimation, { LayoutAnimationStaggerParent } from '@/components/framerAnimations/layoutAnimation';
import StyledImage from '@/components/styledImage';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Container, Grid, Stack, Typography } from '@mui/material';
import { slideLeft } from './accordionSection';
import ProductCard from './productCard';

export default function AccordionPaymentsFeature() {
	return (
		<Accordion>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon className='accordionIcon'/>}
				sx={{ p: 2, px: { md: 5, lg: 25 } }}>
				<LayoutAnimation hasStagger animationConfig={slideLeft}>
					<Stack direction='row' spacing={3} alignItems='center'>
						<StyledImage
							alt='accordion-logo'
							src='images/static-images/payment-accordion.png'
							sx={{ width: { xs: 70, sm: 150 } }}
						/>
						<Typography className='accordionTitle'>Reports and Transactions</Typography>
					</Stack>
				</LayoutAnimation>
			</AccordionSummary>
			<AccordionDetails sx={{ py: { xs: 5, sm: 20 } }}>
				<LayoutAnimationStaggerParent>
					<Container maxWidth='md'>
						<LayoutAnimation hasStagger variant='slideUp'>
							<Typography gutterBottom className='sectionTitle' textAlign='center'>
								All transactions in a glance
							</Typography>
						</LayoutAnimation>
						<LayoutAnimation hasStagger variant='slideUp'>
							<Typography className='sectionSubTitle' textAlign='center'>
								Access a variety of reports directly retrieved from your Clover.
							</Typography>
							<Typography className='sectionSubTitle' textAlign='center'>
								Reports on sales, purchases, inventory stocks, and team commissions—all consolidated in one
								location.
							</Typography>
						</LayoutAnimation>
						<Grid container spacing={3} mt={4}>
							<Grid item xs={12} md={6}>
								<LayoutAnimation hasStagger variant='slideLeft'>
									<ProductCard src='/images/static-images/paidPartially.png' cardMediaStyles={{ bottom: -20 }}>
										<Typography gutterBottom className='cardTitle'>
											Allow partial payments
										</Typography>
										<Typography className='cardParagraph' color='text.secondary'>
											Allow your clients to pay their invoices partially. In addition with
											Invoiss you can create different tenders and record payments.
										</Typography>
									</ProductCard>
								</LayoutAnimation>
							</Grid>
							<Grid item xs={12} md={6}>
								<LayoutAnimation hasStagger variant='slideRight'>
									<ProductCard src='/images/static-images/cardFees.png' cardMediaStyles={{ bottom: -20 }}>
										<Typography gutterBottom className='cardTitle'>
											Card processing fees
										</Typography>
										<Typography className='cardParagraph' color='text.secondary'>
											Save up to 4% on your card processing fees. Invoiss automatically adds a
											fee line to your invoice when your client pays with a card.
										</Typography>
									</ProductCard>
								</LayoutAnimation>
							</Grid>
						</Grid>
					</Container>
				</LayoutAnimationStaggerParent>
			</AccordionDetails>
		</Accordion>
	);
}
