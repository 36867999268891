export default function animatedLinearGradient(
	colors: string[],
	direction: string = 'to right',
	speed: number = 10,
) {
	const gradient = `linear-gradient(${direction}, ${colors.join( ', ' )})`;
	return {
		'backgroundImage'    : gradient,
		'backgroundSize'     : '400% 400%',
		'animation'          : `gradient ${speed}s ease infinite`,
		'@keyframes gradient': {
			'0%'  : { backgroundPosition: '0% 50%' },
			'50%' : { backgroundPosition: '100% 50%' },
			'100%': { backgroundPosition: '0% 50%' },
		},
	};
}
