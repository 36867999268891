import LayoutAnimation, { LayoutAnimationStaggerParent } from '@/components/framerAnimations/layoutAnimation';
import StyledImage from '@/components/styledImage';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Container, Grid, Stack, Typography } from '@mui/material';
import { slideLeft } from './accordionSection';
import ProductCard from './productCard';

export default function AccordionTeamPermissionsFeature() {
	return (
		<Accordion>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon className='accordionIcon'/>}
				sx={{ p: 2, px: { md: 5, lg: 25 } }}>
				<LayoutAnimation hasStagger animationConfig={slideLeft}>
					<Stack direction='row' spacing={3} alignItems='center'>
						<StyledImage
							alt='accordion-logo'
							src='images/static-images/team-accordion.png'
							sx={{ width: { xs: 70, sm: 150 } }}
						/>
						<Typography className='accordionTitle'>Team Permissions</Typography>
					</Stack>
				</LayoutAnimation>
			</AccordionSummary>
			<AccordionDetails sx={{ py: 15 }}>
				<LayoutAnimationStaggerParent>
					<Container maxWidth='md'>
						<LayoutAnimation hasStagger variant='slideUp'>
							<Typography gutterBottom className='sectionTitle' textAlign='center'>
								Grant full access, view-only, or no access permissions for any feature in the app.
							</Typography>
						</LayoutAnimation>
						<LayoutAnimation hasStagger variant='slideUp'>
							<Typography className='sectionSubTitle' textAlign='center'>
								Invite team members with their own credential and control access
							</Typography>
						</LayoutAnimation>
						<Grid container spacing={3} my={{ xs: 3, md: 15 }}>
							<Grid item xs={12} md={6}>
								<LayoutAnimation hasStagger variant='slideLeft'>
									<ProductCard
										src='/images/static-images/permissions.png'
										cardMediaStyles={{ bottom: -20 }}>
										<Typography gutterBottom className='cardTitle'>
											Assign Roles
										</Typography>
										<Typography className='cardParagraph' color='text.secondary'>
											Assign roles to your sales team members and allow them to use only
											what they need
										</Typography>
									</ProductCard>
								</LayoutAnimation>
							</Grid>
							<Grid item xs={12} md={6}>
								<LayoutAnimation hasStagger variant='slideRight'>
									<ProductCard src='/images/static-images/permissions-2.png' cardMediaStyles={{ bottom: -20 }}>
										<Typography gutterBottom className='cardTitle'>
											Assign Permissions
										</Typography>
										<Typography className='cardParagraph' color='text.secondary'>
											Grant and revoke permissions for specific features of the app in real time
										</Typography>
									</ProductCard>
								</LayoutAnimation>
							</Grid>
						</Grid>
					</Container>
				</LayoutAnimationStaggerParent>
			</AccordionDetails>
		</Accordion>
	);
}
