import { Box, Card, CardContent, CardMedia } from '@mui/material';

export default function ProductCard( { children, src, cardMediaStyles }: any ) {
	return (
		<Card
			sx={{
				boxShadow   : 0,
				borderRadius: 3,
				bgcolor     : 'background.default',
			}}>
			<Box sx={{ overflow: 'hidden', height: 350, p: 1, position: 'relative' }}>
				<CardMedia
					component='img'
					sx={{
						width    : '100%',
						objectFit: 'contain',
						position : 'absolute',
						height   : 'auto',
						bottom   : 0,
						left     : 0,
						right    : 0,
						...cardMediaStyles,
					}}
					alt='landing-product-image'
					src={src}
				/>
			</Box>
			<CardContent
				sx={{
					borderTop  : 1,
					borderColor: 'divider',
					px         : 5,
					textAlign  : 'center',
					height     : 230,
				}}>
				{children}
			</CardContent>
		</Card>
	);
}
