import LayoutAnimation, { LayoutAnimationStaggerParent } from '@/components/framerAnimations/layoutAnimation';
import { Container, Stack, Typography } from '@mui/material';
import StyledCustomButton from '../buttons/staticPageButton';

export default function FinalCTASection() {
	
	return (
		<Container
			disableGutters
			maxWidth='xl'
			className='borderedContainer'
			sx={{ p: { xs: 5, md: 10, lg: 15, xl: 25 }, bgcolor: '#1A4C36', color: '#ffffff' }}>
			<LayoutAnimationStaggerParent>
				<Stack spacing={2}>
					<LayoutAnimation hasStagger duration={1} slideUpDistance={90} variant='slideUp'>
						<Typography className='sectionTitle'>
							Get started with a 14 day trial!
						</Typography>
					</LayoutAnimation>
					<LayoutAnimation hasStagger duration={1} slideUpDistance={90} variant='slideUp'>
						<Typography className='sectionSubTitle'>
							Cancel at anytime
						</Typography>
					</LayoutAnimation>
					<LayoutAnimation hasStagger duration={1} slideUpDistance={90} variant='slideUp'>
						<StyledCustomButton href='https://www.clover.com/appmarket/apps/9TDMF3T5AN9T2'>
							Get Started
						</StyledCustomButton>
					</LayoutAnimation>
				</Stack>
			</LayoutAnimationStaggerParent>
		</Container>
	);
	
}
