import LayoutAnimation, { LayoutAnimationStaggerParent } from '@/components/framerAnimations/layoutAnimation';
import StyledImage from '@/components/styledImage';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Container, Grid, Stack, Typography } from '@mui/material';
import StyledCustomButton from '../buttons/staticPageButton';
import { slideLeft } from './accordionSection';
import ProductCard from './productCard';

export default function AccordionOrdersPurchasesFeature() {
	return (
		<Accordion>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon className='accordionIcon'/>}
				sx={{ p: 2, px: { md: 5, lg: 25 } }}>
				<LayoutAnimation hasStagger animationConfig={slideLeft}>
					<Stack direction='row' spacing={3} alignItems='center'>
						<StyledImage
							alt='accordion-logo'
							src='images/static-images/order-accordion.png'
							sx={{ width: { xs: 70, sm: 150 } }}
						/>
						<Typography className='accordionTitle'>
							Purchasing and Inventory
						</Typography>
					</Stack>
				</LayoutAnimation>
			</AccordionSummary>
			<AccordionDetails sx={{ py: { xs: 5, sm: 20 } }}>
				<LayoutAnimationStaggerParent>
					<Container maxWidth='md'>
						<LayoutAnimation hasStagger variant='slideUp'>
							<Typography gutterBottom className='sectionTitle' textAlign='center'>
								Create Purchase orders and update stocks with a click
							</Typography>
						</LayoutAnimation>
						<LayoutAnimation hasStagger variant='slideUp'>
							<Typography
								className='sectionSubTitle'
								color='success.main'
								sx={{ fontWeight: 700 }}
								textAlign='center'>
								Send orders to any vendor with their requested format
							</Typography>
						</LayoutAnimation>
						<Grid container spacing={3} my={{ xs: 3, md: 15 }}>
							<Grid item xs={12} md={6}>
								<LayoutAnimation hasStagger variant='slideLeft'>
									<ProductCard
										src='/images/static-images/turnToInvoice.png'
										cardMediaStyles={{ marginTop: 3 }}>
										<Typography gutterBottom className='cardTitle'>
											Turn Clover Orders into Invoices
										</Typography>
										<Typography className='cardParagraph' color='text.secondary'>
											Generate an invoice for each open clover order and sent it do that client.
										</Typography>
									</ProductCard>
								</LayoutAnimation>
							</Grid>
							<Grid item xs={12} md={6}>
								<LayoutAnimation hasStagger variant='slideRight'>
									<ProductCard src='/images/static-images/clover-inventory-counts.png'>
										<Typography className='cardTitle'>
											Create Purchase Orders
										</Typography>
										<Typography className='cardParagraph' color='text.secondary'>
											Send orders to your vendors and take purchase reports.
											Invoiss will add to your inventory stocks upon receiving the items.
										</Typography>
									</ProductCard>
								</LayoutAnimation>
							</Grid>
							<Grid item xs={12} md={6}>
								<LayoutAnimation hasStagger variant='slideRight'>
									<ProductCard src='/images/static-images/onlineStore.png'>
										<Typography gutterBottom className='cardTitle'>
											Create Online Store
										</Typography>
										<Typography className='cardParagraph' color='text.secondary'>
											Create an online store for your business and sell your products online.
											Invoiss will create orders on your clover and you can get paid or invoice
											them later.
										</Typography>
									</ProductCard>
								</LayoutAnimation>
							</Grid>
							<Grid item xs={12} md={6}>
								<LayoutAnimation hasStagger variant='slideLeft'>
									<ProductCard src='/images/static-images/merge-order.png'>
										<Typography gutterBottom className='cardTitle'>
											Merge multiple orders into one
										</Typography>
										<Typography className='cardParagraph' color='text.secondary'>
											Get paid in one transaction or invoice your client and allow them to view
											their original orders and remit payment.
										</Typography>
									</ProductCard>
								</LayoutAnimation>
							</Grid>
						</Grid>
						<LayoutAnimation hasStagger variant='slideUp'>
							<Stack spacing={1} pt={5} alignItems='center' justifyContent='center'>
								<StyledCustomButton
									href='https://www.clover.com/appmarket/apps/9TDMF3T5AN9T2'
									color='#280'
									target='_blank'>
									Connect Now
								</StyledCustomButton>
								<Typography className='connectTrial' textAlign='center'>
									FREE 14-days
								</Typography>
							</Stack>
						</LayoutAnimation>
					</Container>
				</LayoutAnimationStaggerParent>
			</AccordionDetails>
		</Accordion>
	);
}
