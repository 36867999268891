import useUserInfo from '@/providers/auth/useUserInfo';
import { CompleteSplash } from '@/providers/splash';
import { useStaticTheme } from '@/providers/staticTheme';
import { useRouter } from 'next/router';
import { useAsyncEffect } from 'rooks';
import useGetDeviceInfo from '../hooks/useGetDeviceInfo';
import StaticPageLayout from './static/_layout';
import FeaturesAccordionSection from './static/common/home/accordionSection';
import BusinessBenefitsSection from './static/common/home/businessBenefitsSection';
import FAQSection from './static/common/home/faqSection';
import FinalCTASection from './static/common/home/finalCTAsection';
import HomeHeroSection from './static/common/home/hero';
import SummarizedPricingSection from './static/common/home/pricingSummarized';
import StaticPageBoxWithStylesWrapper from './static/common/home/stylesBoxWrapper';
import TestimonialsSection from './static/common/home/testimonial';

export default function Index() {
	useStaticTheme( 'light' );
	const router = useRouter();
	const { isCloverDevice } = useGetDeviceInfo();
	const { user, staff } = useUserInfo();
	
	// Only for when users are on the "/" route
	useAsyncEffect( async () => {
		if ( staff ) await router.replace( '/dashboard' );
		else if ( user?.salesAgent?.id ) await router.replace( '/partner/dashboard' );
		else if ( user ) await router.replace( '/welcome' );
		else if ( isCloverDevice ) await router.replace( '/login' ); // prevent clover devices to see the index page
	}, [ user, staff ] );
	
	if ( user || staff ) return null;
	
	return (
		<StaticPageLayout
			hasAnimation
			navbarContainerProps={{ maxWidth: 'xl' }}
			footerContainerProps={{ maxWidth: 'xl' }}>
			<CompleteSplash/>
			<StaticPageBoxWithStylesWrapper>
				<HomeHeroSection/>
				{/*<TrustedSection/>*/}
				<FeaturesAccordionSection/>
				<BusinessBenefitsSection/>
				<TestimonialsSection/>
				<SummarizedPricingSection/>
				<FAQSection/>
				<FinalCTASection/>
			</StaticPageBoxWithStylesWrapper>
		</StaticPageLayout>
	);
}
